<template>
  <div class="w-screen h-screen flex">
    <div class="w-full md:w-1/2 bg-gray-100">
      <div class="container flex flex-col w-2/3 mt-20">
        <router-link to="/">
          <img
            src="@/_assets/images/hive_logo.svg"
            alt=""
            class="w-48 h-16 object-contain mb-12"
          />
        </router-link>
        <h1 class="font-bold text-2xl text-darkblue-800 mt-12">
          Crea il tuo account!
        </h1>
        <h3 class="py-2 font-medium">
          Registrati in pochi step, ed inizia ad acquistare!
        </h3>
        <form @submit.prevent="signUp" class="flex flex-col">
          <input
            type="text"
            name="name"
            v-model="name"
            class="w-full my-2"
            placeholder="Nome"
            :class="$v.name.$invalid ? 'border border-red-500' : null"
          />
          <span class="text-red-500"></span>
          <input
            type="text"
            name="surname"
            v-model="familyName"
            placeholder="Cognome"
            class="w-full my-2"
          />
          <span class="text-red-500"></span>
          <input
            type="text"
            name="email"
            v-model="email"
            class="w-full my-2"
            placeholder="Email"
          />
          <span class="text-red-500"></span>
          <input
            type="password"
            name="password"
            v-model="password"
            placeholder="Password"
            class="w-full my-2"
          />
          <span class="text-red-500"></span>
          <input
            type="password"
            name="passwordConfirm"
            v-model="passwordConfirm"
            placeholder="Conferma Password"
            class="w-full my-2"
          />
          <span class="text-red-500"></span>
          <button
            type="submit"
            class="btn btn-cta mt-8"
            :class="$v.$invalid ? ' cursor-not-allowed' : null"
            :disabled="$v.$invalid"
          >
            Registrati
          </button>
        </form>
        <div class="mt-2">
          <div class="text-red-500">{{ error }}</div>
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-1/2 bg-darkblue-500 text-white flex justify-center items-center"
    >
      <img src="@/_assets/images/pay_img.png" class="" />
    </div>
  </div>
</template>
<script>
//import auth from "../_utils/auth";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  name: "SignUp",
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      isLoading: false,
      familyName: "",
      email: "",
      password: "",
      passwordConfirm: ""
    };
  },

  computed: {
    error: {
      get() {
        return this.$store.getters["auth/error"];
      }
    }
  },
  validations: {
    name: { required, minLength: minLength(4) },
    familyName: { required, minLength: minLength(4) },
    email: { required, minLength: minLength(4), email },
    password: { required, minLength: minLength(8) },
    passwordConfirm: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs("password")
    }
  },
  methods: {
    signUp() {
      console.log("method signup called: ", this.email);
      try {
        this.isLoading = true;
      } catch (error) {
        console.log("Errore ", error);
      } finally {
        this.isLoading = false;
      }
      this.$store.dispatch("auth/signUp", {
        email: this.email,
        password: this.password,
        name: this.name,
        family_name: this.familyName
      });
    }
  }
};
</script>
<style lang="postcss" scoped>
/* input {
  @apply border border-gray-300 rounded-sm w-full px-4 py-2 mb-4;
}
button {
  @apply px-8 py-2 border border-gray-300 rounded-sm mb-4;
}
button:hover {
  @apply bg-gray-200 shadow-sm;
} */
</style>
